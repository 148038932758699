<template>
  <div style="overflow-y: scroll">
    <div class="tableOne">
      <div class="title_boxx">
        <div
          @click="
            () => {
              $router.go(-1);
            }
          "
          style="cursor: pointer"
          class="back_link"
        >
          <div class="back_icon">
            <b-icon
              icon="arrow-left"
              style="width: 25px; height: 25px; color: #2f3641"
            ></b-icon>
          </div>
          <h2>Участники</h2>
        </div>
      </div>

      <div class="table_boxx">
        <div class="row Form_box_row">
          <div class="col-lg-8">
            <b-form class="b_form" @submit.prevent="addUser">
              <div class="inputs_one">
                <!-- First name -->
                <div class="boxx">
                  <label class="title" for="user_name">Имя<span class="requiredInput">*</span></label>
                  <b-form-input
                    class="inputt"
                    id="user_name"
                    required
                    v-model="form.first_name"
                  />
                </div>

                <!-- Last Name -->
                <div class="boxx">
                  <label class="title" for="user_name">Фамилия<span class="requiredInput">*</span></label>
                  <b-form-input
                    class="inputt"
                    id="user_name"
                    required
                    v-model="form.last_name"
                  />
                </div>

                <!-- Middle Name -->
                <div class="boxx">
                  <label class="title" for="middle_name">Отчество</label>
                  <b-form-input
                    class="inputt"
                    id="middle_name"
                    v-model="form.middle_name"
                  />
                </div>

                <!-- Date of birth -->
                <div class="boxx">
                  <label class="title" for="date_birth">Дата рождения</label>
                  <input
                    type="date"
                    v-model="form.birth_date"
                    class="custom-select"
                    id="date_birth"
                  />
                </div>

                <!--Gender-->
                <div class="boxx">
                  <label class="title" for="country">Пол<span class="requiredInput">*</span></label>
                  <b-form-select
                    id="country"
                    v-model="form.gender"
                    class="custom-select"
                    required
                  >
                    <b-select-option value="M">Мужчина</b-select-option>
                    <b-select-option value="F">Женщина</b-select-option>
                  </b-form-select>
                </div>

                <!--status-->
                <!-- <div class="boxx">
                  <label class="title" for="country">Статус</label>
                  <b-form-select
                    id="country"
                    required
                    v-model="form.status"
                    class="custom-select"
                  >
                    <b-select-option
                      v-for="(status, index) in statuses"
                      :value="status.value"
                      :key="index"
                    >
                      {{ status.label }}
                    </b-select-option>
                  </b-form-select>
                </div> -->

                <!-- <div class="boxx">
                  <label class="title" for="passport_id"
                    >Серийный номер паспорта</label
                  >
                  <b-form-input
                    class="inputt"
                    id="passport_id"
                    v-model="form.passport_id"
                    @input="form.passport_id = form.passport_id.toUpperCase()"
                  />
                </div> -->

                <!-- Position -->
                <!-- <div class="boxx">
                  <label class="title" for="Position">Позиция</label>
                  <b-form-input
                    class="inputt"
                    id="Position"
                    v-model="form.position"
                  />
                </div> -->

                <!-- Organisation -->
                <!-- <div class="boxx">
                  <label class="title" for="organisation">Организация</label>
                  <b-form-input
                    class="inputt"
                    id="organisation"
                    required
                    v-model="form.organization"
                  />
                </div> -->

                <div class="boxx">
                  <label class="title" for="country">Тип регистрации<span class="requiredInput">*</span></label>
                  <b-form-select
                    id="country"
                    v-model="form.event_registration_type"
                    required
                    class="custom-select"
                  >
                    <b-select-option
                      v-for="(type, index) in registrationTypes"
                      :key="index"
                      :value="type.value"
                    >
                      {{ type.label }}
                    </b-select-option>
                  </b-form-select>
                </div>

                <!-- <div class="boxx">
                  <label class="title" for="passport_id">Рабочий адрес</label>
                  <b-form-input
                    class="inputt"
                    id="passport_id"
                    v-model="form.work_address"
                  />
                </div> -->

                <!-- Save Btn -->
                <div class="btn_box">
                  <button class="btn_box" type="submit">Сохранить</button>
                </div>
              </div>

              <div class="inputs_one">
                <!-- Organisation -->
                <div class="boxx">
                  <label class="title" for="organisation">
                    Организация / Учебное заведение
                  </label>
                  <b-form-input
                    class="inputt"
                    id="organisation"
                    v-model="form.organization"
                  />
                </div>

                <!-- Position -->
                <div class="boxx">
                  <label class="title" for="Position">Должность</label>
                  <b-form-input
                    class="inputt"
                    id="Position"
                    v-model="form.position"
                  />
                </div>

                <!-- Country -->
                <div class="boxx">
                  <label class="title" for="country">Страна</label>
                  <b-form-select
                    id="country"
                    v-model="form.country"
                    class="custom-select"
                  >
                    <b-select-option
                      v-for="(item, index) in countries"
                      :value="item.id"
                      :key="index"
                    >
                      {{ item.title.en }}
                    </b-select-option>
                  </b-form-select>
                </div>

                <!-- City -->
                <div class="boxx">
                  <label class="title" for="City">Город</label>
                  <b-form-input
                    class="inputt"
                    id="City"
                    v-model="form.city"
                  />
                </div>

                <!-- Email -->
                <div class="boxx">
                  <label class="title" for="email">Электронная почта<span class="requiredInput">*</span></label>
                  <b-form-input
                    class="inputt"
                    id="email"
                    required
                    v-model="form.email"
                    type="email"
                  />
                </div>

                <div class="boxx">
                  <label class="title" for="phone">Номер телефона</label>
                  <b-form-input
                    class="inputt"
                    id="phone"
                    v-model="form.phone_number"
                    ref="phoneInput"
                  />
                </div>

                <!-- <div class="boxx">
                  <label class="title" for="country">Статус</label>
                  <b-form-select
                    id="country"
                    required
                    v-model="form.status"
                    class="custom-select"
                  >
                    <b-select-option
                      v-for="(status, index) in statuses"
                      :value="status.value"
                      :key="index"
                    >
                      {{ status.label }}
                    </b-select-option>
                  </b-form-select>
                </div> -->

                <!-- Download file -->
                <div class="flexingCamera">
                  <label for="file" class="file">
                    Загрузите свою фотографию
                    <div class="box_avatar">
                      <div class="img_box">
                        <template
                          v-if="src && src !== 'https://api.planerps.uz/media/nope'"
                        >
                          <img class="user-image" :src="src"/>
                        </template>
                        <div
                          @click="browse()"
                          class="img_back"
                          :class="{ 'img_back-custom': !src }"
                        >
                          <input
                            type="file"
                            @input="input"
                            style="display: none"
                            ref="file"
                          />
                          <img src="@/assets/+.svg"/>
                        </div>
                      </div>
                    </div>
                  </label>
                  <img
                    src="../../assets/Camera.png"
                    @click="onClickCameraImg('profile')"
                    class="shotOnCamera"
                    alt="Camera"
                  />
                </div>
                <div class="bigModal" :class="{ bigModalActive: activeCamera }">
                  <span class="bigModalClose" @click="toggleCamera"></span>
                  <div class="cameraParent">
                    <div>
                      <vue-web-cam
                        v-if="showCamera"
                        ref="webcam"
                        @stopped="onStopped"
                        @error="onError"
                        @cameras="onCameras"
                        @camera-change="onCameraChange"
                        :device-id="deviceId"
                        width="400"
                        height="330"
                      />
                    </div>
                    <select v-model="camera">
                      <option disabled>-- Select Device --</option>
                      <option
                        v-for="device in devices"
                        :key="device.deviceId"
                        :value="device.deviceId"
                      >
                        {{ device.label }}
                      </option>
                    </select>
                    <div class="buttonLike" @click="onCapture">
                      Сделать снимок
                    </div>

                  </div>
                </div>

                <!--<div class="buttonLike" @click="onStop">Stop Camera</div>-->
                <!--<div class="buttonLike" @click="onStart">Start Camera</div>-->

                <!-- <div class="flexingCamera">
                  <label for="file" class="file">
                    Загрузить фото паспорта
                    <div class="box_avatar">
                      <div class="img_box img_box-passport">
                        <template
                          v-if="
                            srcPassport &&
                            srcPassport !== 'https://api.planerps.uz/media/nope'
                          "
                        >
                          <img :src="srcPassport" />
                        </template>
                        <div
                          @click="browsePassport()"
                          class="img_back"
                          :class="{ 'img_back-custom': !srcPassport }"
                        >
                          <input
                            type="file"
                            @input="inputPasportImage"
                            style="display: none"
                            ref="filePassport"
                          />
                          <img src="@/assets/+.svg" />
                        </div>
                      </div>
                    </div>
                  </label>
                  <img
                    src="../../assets/Camera.png"
                    @click="onClickCameraImg('passport')"
                    class="shotOnCamera"
                    alt="Camera"
                  />
                </div> -->
              </div>
            </b-form>
          </div>
          <b-overlay :show="busy" style="z-index: 2000" no-wrap></b-overlay>
        </div>
      </div>
    </div>
    <notes ref="message"></notes>
  </div>
</template>

<script>
import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input/intlTelInputWithUtils"

import {
  BTable,
  BRow,
  BCol,
  BPagination,
  BImg,
  BButton,
  BIcon,
  BOverlay,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";
// import Input from "@/components/Inputs/Input.vue";
import {instance} from "@/utils/composables";

// import "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/css/intlTelInput.min.css";
// import "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/intlTelInput.min.js";
// import "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.js";

export default {
  components: {
    // Input,
    // ToastificationContent,
    BTable,
    BRow,
    BCol,
    BIcon,
    BPagination,
    BImg,
    BButton,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  data() {
    return {
      form: {
        id: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        birth_date: "",
        country: "",
        city: "",
        email: "",
        event: "",
        gender: "",
        image: "",
        organization: "",
        passport_id: "",
        passport_image: "",
        position: "",
        phone_number: "",
        event_registration_type: "",
        work_address: "",
        status: "",
      },
      file: null,
      filePassport: null,
      busy: false,
      src: null,
      srcPassport: null,
      copy_link: "https://event.net",
      activeCamera: false,
      img: null,
      camera: null,
      deviceId: null,
      devices: [],
      countries: [],
      statuses: [
        {value: "pending", label: "PENDING"},
        {value: "accepted", label: "ACCEPTED"},
        {value: "canceled", label: "CANCELED"},
      ],
      registrationTypes: [
        // {value: "ATT", label: "Участник"},
        // {value: "SPE", label: "Спикер"},
        // {value: "MOD", label: "Модератор"},
        // // {value: "GOF", label: "Government official"},
        // {value: "MED", label: "СМИ"},
        // // {value: "LME", text: "Local media"},
        // {value: "ORG", label: "Организатор"},
        // {value: "VOL", label: "Волонтер"},
        // {value: "VIP", label: "VIP"},
        // {value: "STF", label: "Технический персонал"}
      ],
      showCamera: false,
      selectedImageType: null,
    };
  },
  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
  },
  beforeDestroy() {
    if (this.iti) {
      this.iti.destroy()
    }
    this.showCamera = false;
    this.onStop();
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
      this.$refs.webcam.stop();
    },
    devices: function () {
      const [first, ...tail] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
      this.$refs.webcam.stop();
    },
    activeCamera(value) {
      if (value) {
        this.$refs.webcam.start();
      } else {
        this.$refs.webcam.stop();
      }
    },
  },
  methods: {
    onClickCameraImg(type) {
      this.selectedImageType = type;
      this.showCamera = true;
      this.toggleCamera();
    },
    async EditUser() {
      try {
        this.busy = true;
        const response = await instance.get(
          `/participant/${this.$route.params.id}/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.accessToken}`,
            },
          }
        );
        this.form.first_name = response.data.first_name;
        this.form.middle_name = response.data.middle_name;
        this.form.position = response.data.position;
        this.form.phone_number = response.data.phone_number;
        this.form.organization = response.data.organization;
        this.form.email = response.data.email;
        this.form.last_name = response.data.last_name;
        this.form.country = response.data.country;
        this.form.city = response.data.city;
        this.src = response.data.image;
        this.srcPassport = response.data.passport_image;
        this.form.event = response.data.event;
        this.form.work_address = response.data.work_address;
        this.form.event_registration_type = response.data.event_registration_type;
        this.form.gender = response.data.gender;
        this.form.status = response.data.status;
        this.form.passport_id = response.data.passport_id;
        this.form.birth_date = response.data.birth_date;
        this.allData = response.data;
      } catch (err) {
        console.log(err);
        await this.$router.push(`/users/${this.form.event}`);
      } finally {
        this.busy = false;
      }
    },
    getCountries() {
      instance
      .get("/event/countries/", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      })
      .then(({data}) => {
        this.countries = data;
      })
      .catch(() => {
        this.$toast.warning("Страны не загружаются");
      });
    },
    async getEventRegistrationTypes() {
      try {
        await this.$store.dispatch("getEventRegistrationTypes", this.form.event)
        const types = this.$store.state.eventRegistrationTypes
        // console.log("types: ", types)
        if (types && types.length > 0) {
          types.forEach((regType) => {
            this.registrationTypes.push({value: regType.id, label: regType.registration_type.title.ru});
          });
          // console.log("regTypes: ", this.registrationTypes)
        }
      } catch (err) {
        console.error(err);
      }
    },
    // Click buttons
    onCapture() {
      this.img = null;
      this.toggleCamera();
      this.img = this.$refs.webcam.capture();
      if (this.selectedImageType === "profile") {
        this.src = this.img;
      } else {
        this.srcPassport = this.img;
      }

      function dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: mime});
      }

      //Usage example:
      let filed = dataURLtoFile(
        this.img,
        this.selectedImageType === "profile"
          ? "Profile-picture.jpeg"
          : "Passport-picture.jpeg"
      );

      if (this.selectedImageType === "profile") {
        this.file = filed;
      } else {
        this.filePassport = filed;
      }
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream);
    },
    onStop() {
      if (this.$refs.webcam) this.$refs.webcam.stop();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      this.$toast.error(error);
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
    },
    browse() {
      this.$refs.file.click();
    },
    browsePassport() {
      this.$refs.filePassport.click();
    },
    input(e) {
      this.file = e.target.files[0];
      const img = new FileReader();
      img.readAsDataURL(e.target.files[0]);
      img.onload = (e) => {
        this.src = e.target.result;
      };
    },
    inputPasportImage(e) {
      this.filePassport = e.target.files[0];
      const image = new FileReader();
      image.readAsDataURL(e.target.files[0]);
      image.onload = (e) => {
        this.srcPassport = e.target.result;
      };
    },
    async addUser() {
      try {
        this.busy = true;
        const data = new FormData();
        data.append("event", this.form.event);
        data.append("email", this.form.email);
        data.append("first_name", this.form.first_name);
        data.append("last_name", this.form.last_name);
        data.append("middle_name", this.form.middle_name);
        data.append("position", this.form.position ? this.form.position : "");
        data.append("phone_number", this.form.phone_number ? this.form.phone_number : "");
        data.append("organization", this.form.organization ? this.form.organization : "");
        data.append("country", this.form.country ? this.form.country : "");
        data.append("city", this.form.city ? this.form.city : "");
        data.append("birth_date", this.form.birth_date ? this.form.birth_date : "");
        data.append("gender", this.form.gender ? this.form.gender : "");
        data.append("work_address", this.form.work_address ? this.form.work_address : "");
        data.append("passport_id", this.form.passport_id ? this.form.passport_id : "");
        data.append("event_registration_type", this.form.event_registration_type);
        if (Boolean(this.file)) {
          data.append("image", this.file);
        }
        if (Boolean(this.filePassport)) {
          data.append("passport_image", this.filePassport);
        }
        if (!this.form.id) {
          await this.$store.dispatch("addUser", data);
          this.$toast.success("Участник успешно добавлен");
          await this.$router.go(-1);
        } else {
          await this.$store.dispatch("patchUser", {
            id: this.form.id,
            data,
          });
          this.$toast.success("Участник успешно изменён");
          await this.$router.go(-1);
        }
      } catch (err) {
        console.log("error: ", err.response);
        if (err.response?.data?.error?.message) {
          this.$toast.error(err.response.data.error.message);
        } else if (err.response?.data?.message) {
          this.$toast.error(err.response.data.message);
        } else {
          this.$toast.error(err.response.data);
        }
      } finally {
        this.busy = false;
      }
    },
    toggleCamera() {
      this.activeCamera = !this.activeCamera;
    },
    initPhoneInput() {
      const phoneInput = this.$refs.phoneInput;

      // Initialize the plugin
      const iti = intlTelInput(phoneInput, {
        // Add any additional options here
      });

      // Example: Listen for the "change" event
      phoneInput.addEventListener("change", () => {
        // Handle phone number change
        console.log("Phone number changed:", iti.getNumber());
      });
    },
  },
  async mounted() {
    const input = this.$refs.phoneInput.$el
    this.iti = intlTelInput(input, {
      // initialCountry: 'uz',
      formatOnDisplay: true,
      strictMode: true,
      separateDialCode: false,
      autoPlaceholder: false,
    })

    this.form.event = localStorage.getItem("event");
    if (this.$route.params.id) {
      this.form.id = this.$route.params.id;
      await this.EditUser();
    }
    this.onStop();
    this.getCountries();
    // this.initPhoneInput();
    await this.getEventRegistrationTypes();
  },
};
</script>

<style>
.cameraParent {
  padding: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  .buttonLike {
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    margin: 10px 0;
    cursor: pointer;
    background: #354b9c;
  }

  select {
    width: 100%;
    font-size: 16px !important;
    color: #2f3641;
    padding: 10px !important;
    border-radius: 15px;
  }
}

.bigModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  display: none;
}

.bigModalActive {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bigModalClose {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 30px;
  aspect-ratio: 1.5/1;
  cursor: pointer;
}

.bigModalClose::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  top: 50%;
  background: #fff;
  transform: rotate(45deg);
}

.bigModalClose::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  top: 50%;
  background: #fff;
  transform: rotate(-45deg);
}

.flexingCamera {
  display: flex;
  align-items: center;
}

.shotOnCamera {
  cursor: pointer;
  margin: 10px auto;
}

.file {
  cursor: pointer;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Main {
  height: 100vh;
  width: 100%;
  background: #f8f7ff;
}

.custom-select {
  width: 100%;
  height: 45px;
  padding: 0 25px 0 20px;
  border-radius: 15px;
  border: 1px solid #ced4da;
}

.img_box img {
  object-fit: contain !important;
}

.iti {
  width: 100%;
}

#phone {
  padding-left: 50px !important;
}

.requiredInput {
  color: red;
}
</style>