<template>
  <div class="Main" style="overflow-y: scroll">
    <div class="tableOne">
      <div class="search_and_profil">
        <div class="input_search">
          <label for="inputt" class="iconn">
            <b-icon
              class="icon"
              icon="search"
              style="width: 20px; height: 20px"
            ></b-icon>
          </label>
          <div class="input_box">
            <input
              class="imput_in"
              type="search"
              id="inputt"
              v-model="filter"
              @input="onInputSearch"
              placeholder="Поиск"
            />
          </div>
        </div>
      </div>
      <div class="title_boxx">
        <div>
          <h2>Пользователи</h2>
        </div>
        <div class="double_btn">
          <div @click="() => $refs.xls.click()" class="print">
            <input
              type="file"
              @input="upload"
              style="display: none"
              ref="xls"
            />
          </div>
          <router-link to="/app/addSuperuser" class="add_btnn">
            <button>
              <span
              ><b-icon
                class="icon"
                icon="plus"
                style="width: 20px; height: 20px"
              ></b-icon
              ></span>
              Добавить пользователя
            </button>
          </router-link>
        </div>
      </div>

      <div class="table_box">
        <div class="table_title with_category">
          <div>
            <h3>Последние Зарегистрированные</h3>
          </div>
        </div>
        <b-table
          id="my-table"
          class="table_in"
          :table-variant="tableVariant"
          show-empty
          :items="items"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          ref="selectableTable"
          :filter-included-fields="filterOn"
          striped
          responsive
          :hover="true"
        >
          <template #cell(event)="data">
            <div>
              {{ data.item.event?.id }}
            </div>
          </template>
          <template #cell(title)="data">
            <div>
              {{ data.item.title }}
            </div>
          </template>
          <template #cell(role)="data">
            <b-form-tag
              style="font-weight: bold;"
              disabled
              :variant="data.item.role ==='user'?'success':data.item.role ==='admin'?'primary':'danger'"
            >{{ data.item.role?.toUpperCase() }}
            </b-form-tag>
          </template>
          <template #cell(action)="data">
            <div class="boxx">
              <b-button
                variant="primary"
                class="btn-icon custom_class"
                @click="changePassword(data.item.id)"
              >
                <b-icon
                  icon="lock"
                  variant="light"
                ></b-icon>
              </b-button>
              <router-link :to="'/app/editAdmin/' + data.item.id">
                <div class="edit_box">
                  <b-icon
                    icon="pencil"
                    style="width: 15px; height: 15px; color: #fff"
                  ></b-icon>
                </div>
              </router-link>
              <b-button
                variant="danger"
                class="btn-icon"
                @click="settingUser(data.item.id)"
              >
                <b-icon
                  icon="trash"
                  style="width: 15px; height: 15px; color: #fff"
                ></b-icon>
              </b-button>
            </div>
          </template>
        </b-table>
        <!-- loading bar -->
        <b-overlay :show="busy" no-wrap></b-overlay>

        <!-- Pagination -->
        <div class="box_pagination" v-if="!busy">
          <div>Все: <strong> {{ totalRows }}</strong></div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            @change="onChangePagination"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </div>
    <!-- Modal form start -->
    <div v-if="show_modal" class="fixedP" style="z-index: 1000">
      <div class="row row_box">
        <div class="col-8 col-sm-7 col-md-6 col-lg-5 col-xl-4">
          <div class="box">
            <div class="title">
              <h2>Удалить пользователя</h2>
            </div>
            <div class="paragrf">
              <p>
                Вы уверены,
                <br/>
                что хотите удалить <br/>
                этого пользователя
              </p>
            </div>
            <button @click="deleteItemFunc" class="closee">Удалить</button>
            <button @click="close_funk" class="close_btn">Отмена</button>
          </div>
          <b-overlay :show="busy" no-wrap></b-overlay>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      centered
      v-model="modalShow"
      @show="resetModal"
      @hidden="resetModal"
      hide-footer
    >
      <b-form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Введите старый пароль"
          label-for="old-password"
        >
          <b-form-input
            id="old-password"
            class="name-input"
            v-model="formPassword.old_password"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Введите новый пароль"
          label-for="new-password"
        >
          <b-form-input
            id="new-password"
            class="name-input"
            v-model="formPassword.new_password"
            required
          ></b-form-input>
        </b-form-group>
        <b-button class="custom-btn" @click="modalShow = false">Cancel</b-button>
        <b-button class="custom-btn" variant="primary" type="submit">Saved</b-button>
      </b-form>
      <b-overlay :show="busyPassword" no-wrap></b-overlay>
    </b-modal>
    <notes ref="message"></notes>
  </div>
</template>

<script>
import print from "print-js";
import ToastificationContent from "@/components/ToastificationContent.vue";
import {
  BTable,
  BRow,
  BCol,
  BPagination,
  BImg,
  BButton,
  BIcon,
  BOverlay,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";
import {instance} from "@/utils/composables";

export default {
  components: {
    ToastificationContent,
    BTable,
    BRow,
    BCol,
    BIcon,
    BPagination,
    BImg,
    BButton,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  data() {
    return {
      show_modal: false,
      forDeleting: [],
      tableVariant: "light",
      color: "#354B9C",
      selectedCategory: {},
      busy: true,
      busyPassword: false,
      items: [],
      fields: [
        {
          key: "event",
          label: "Event",
          class: "text-center",
          sortable: true,
        },
        {
          key: "first_name",
          label: "Имя",
          class: "text-center",
          sortable: true,
        },
        {
          key: "last_name",
          label: "Фамилия",
          class: "text-start",
          sortable: true,
        },
        {
          key: "role",
          label: "Статус",
          sortable: true,
        },
        {
          key: "date_joined",
          label: "Дата регистрации",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "action",
          label: "Действие",
          class: "text-center",
        },
      ],
      perPage: 15,
      totalItems: 1,
      active: true,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: "",
      filterOn: [],
      totalRows: 1,
      Item_id: null,
      deletingUser: null,
      file: null,
      selectedUser: null,
      formPassword: {
        old_password: null,
        new_password: null,
      },
      modalShow: false
    };
  },
  async mounted() {
    await this.getData();
  },
  computed: {
    currentPage() {
      return this.$route.query.page ? this.$route.query.page : 1
    }
  },
  methods: {
    onInputSearch() {
      clearTimeout(this.timeout)
      if (this.$route.query.page !== '1') {
        this.$router.replace({query: {page: '1'}})
      }
      this.timeout = setTimeout(() => this.getData(), 1000)

    },
    onChangePagination(val) {
      this.$router.replace({query: {page: val}}).then(() => {
        this.getData()
      })
    },
    resetModal() {
      this.formPassword = {
        old_password: null,
        new_password: null,
      }
    },
    handleSubmit() {
      this.busyPassword = true;
      let formData = new FormData();
      formData.append('old_password', this.formPassword.old_password);
      formData.append('new_password', this.formPassword.new_password);

      instance.post(`user/${this.selectedUser}/password-change/`, formData)
      .then(() => {
        this.$refs.modal.hide();
        this.$toast.success("Пароль успешно изменен");
        this.getData();
      })
      .catch((err) => {
        this.$toast.error(err.response.data.error_message)
      }).finally(() => {
        this.busyPassword = false
      })
    },
    changePassword(userId) {
      this.selectedUser = userId
      this.$refs.modal.show()
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    async importXls() {
      try {
        let id = this.$route.params.id;
        await this.$store.dispatch("export", id);
      } catch (err) {
        console.log(err);
      }
    },
    async deleteMultipleData() {
      try {
        this.busy = true;
        await this.$store.dispatch("deleteMultiple", {
          ids: this.forDeleting,
        });
        this.$refs.selectableTable.clearSelected();
        await this.getData();
      } catch (err) {
        console.log(err);
      } finally {
        this.busy = false;
      }
    },
    async delete_funk() {
      try {
        this.busy = true;
        await this.$store.dispatch("deleteUser", this.deletingUser);
        await this.getData();
        this.show_modal = !this.show_modal;
        this.deletingUser = null;
      } catch (error) {
        console.log(error);
      } finally {
        this.busy = false;
      }
    },
    close_funk() {
      this.show_modal = !this.show_modal;
    },
    async upload(e) {
      try {
        this.busy = true;
        this.file = e.target.files[0];
        const data = new FormData();
        data.append("event", this.$route.params.id);
        data.append("file", this.file);
        await this.$store.dispatch("uploadFile", data);
        await this.getData();
      } catch (er) {
        console.log(er);
      } finally {
        this.busy = false;
      }
    },
    settingUser(id) {
      this.deleteItem = id;
      this.show_modal = true;
    },
    // Delete Items
    async deleteItemFunc() {
      try {
        this.busy = true;
        const id = this.deleteItem;
        await this.$store.dispatch("deleteAdmin", id);
        await this.getData();
      } catch (err) {
        console.log(err);
      } finally {
        this.show_modal = false;
        this.busy = false;
      }
    },
    // Print Item
    async Print(id) {
      try {
        this.busy = true;
        //  a.print()
        const response = await instance.get(`/participant/${id}/badge/`, {
          headers: {
            Authorization: `Bearer ${localStorage.accessToken}`,
          },
        });
        print(response.data.file);
        /*
        const base64str = response.data.binary;
        const binary = atob(base64str.replace(/\s/g, ""));
        const len = binary.length;
        const buffer = new ArrayBuffer(len);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
        }
        const blob = new Blob([view], {type: "application/pdf"});
        const blobURL = URL.createObjectURL(blob);
        print(blobURL);
        */
      } catch (err) {
        console.log(err);
        alert("Для этого события не сгенерирован шаблон");
      } finally {
        this.busy = false;
      }
    },
    pageClicked(e) {
      console.log(e);
    },
    getData() {
      this.busy = this.busy ? this.busy : true;
      let page = this.$route.query.page ? this.$route.query.page : 1
      instance.get(`/user/?role=admin&role=staff&role=customer&page=${page}&page_size=${this.perPage}&search=${this.filter}`, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }).then(({data}) => {
        this.items = data.results;
        this.totalRows = data.count;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.busy = false;
      })

    },
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Main {
  height: 100vh;
  width: 100%;
  background: #f8f7ff;
}

.custom_class {
  margin-right: 10px !important;
}

.modal-dialog .modal-content {
  padding: 15px !important;
}

.form-group {
  margin-top: 20px;
}

.name-input {
  width: 100%;
  margin-top: 8px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.custom-btn {
  margin-right: 10px;
  margin-top: 20px;
  width: calc(50% - 10px);
  padding: 8px 0;
  border-radius: 15px;
}

.box_pagination {
  margin-top: 10px;
}
</style>
