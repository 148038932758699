<template>
  <div class="Main" style="overflow-y: scroll">
    <div class="tableOne">
      <div class="search_and_profil">
        <div class="input_search">
          <label for="inputt" class="iconn">
            <b-icon
              class="icon"
              icon="search"
              style="width: 20px; height: 20px"
            ></b-icon>
          </label>
          <div class="input_box">
            <input
              class="imput_in"
              type="search"
              id="inputt"
              v-model="filter"
              @input="onInputSearch"
              placeholder="Поиск"
            />
          </div>
        </div>
        <div class="actions-input">
          <Dropdown
            :options="countries"
            v-on:selected="onFilterCountry"
            :disabled="false"
            :maxItem="300"
            class="m-2 custom-input dropdown-custom"
            placeholder="Select country"
          >
          </Dropdown>
          <b-form-select
            v-model="selectedRegType"
            :options="regTypes"
            size="sm"
            style="width: auto"
            class="custom-input"
            @change="onChangeFilter"
          ></b-form-select>

          <b-form-select
            v-model="selectedStatus"
            :options="statuses"
            size="sm"
            class="m-2 custom-input"
            style="width: auto"
            @change="onChangeFilter"
          ></b-form-select>
        </div>
      </div>
      <div class="title_boxx">
        <div>
          <h2>Участники</h2>
        </div>
        <div class="double_btn">
          <div style="margin-right: 20px">
            <button @click="importXls">
              <span class="download">
                <b-icon
                  class="icon"
                  icon="download"
                  style="
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                  "
                ></b-icon>
                Экспорт .xlsx
              </span>
            </button>
          </div>
          <router-link :to="'/app/addUser'" class="add_btnn">
            <button>
              <span
              ><b-icon
                class="icon"
                icon="plus"
                style="width: 20px; height: 20px"
              ></b-icon
              ></span>
              Добавить Участника
            </button>
          </router-link>
        </div>
      </div>

      <div class="table_box">
        <!--        <div class="table_title with_category">-->
        <!--          <div>-->
        <!--            <h3>Последние Зарегистрированные</h3>-->
        <!--          </div>-->
        <!--        </div>-->

        <div style="margin: 5px 0 20px; display: flex; align-items: center">
          <b-button
            class="text-white"
            variant="info"
            size="sm"
            @click="selectAllRows"
          >Выбрать всех
          </b-button>
          <b-button
            class="text-white"
            variant="info"
            size="sm"
            @click="clearSelected"
          >Отменить всех
          </b-button>
          <b-button
            class="text-white"
            variant="danger"
            size="sm"
            v-if="forDeleting.length > 0"
            @click="deleteMultipleData"
          >Удалить выбранных
          </b-button>
          <b-button
            class="text-white"
            variant="info"
            size="sm"
            v-if="forDeleting.length > 0"
            @click="sendEmail"
          >Отправить Email
          </b-button>
          <b-form-select
            v-model="selectedEmail"
            :options="options"
            v-if="forDeleting.length > 0"
            size="sm"
            style="width: auto"
          ></b-form-select>
        </div>

        <b-table
          id="my-table"
          class="table_in"
          :table-variant="tableVariant"
          show-empty
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          ref="selectableTable"
          :filter-included-fields="filterOn"
          responsive
          fixed
          small
        >
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{
                width:
                  field.key === 'action' || field.key === 'email'
                    ? '240px'
                    : field.key === 'selected'
                    ? '50px'
                    : field.key === 'event'
                    ? '70px'
                    : field.key === 'image'
                    ? '100px'
                    : field.key === 'session_title'
                    ? '300px'
                    : '160px',
              }"
            />
          </template>
          <template #cell(selected)="row">
            <b-form-group style="margin-left: 5px">
              <b-form-checkbox
                @change="onChangeCheckBox(row.item.selected, row.item.id)"
                v-model="row.item.selected"
                variant="outline-secondary"
                class="mr-1"
              >
              </b-form-checkbox>
            </b-form-group>
          </template>
          <template #cell(image)="data">
            <div v-if="!data.item.image">
              <!--<b-icon
                icon="circle-fill"
                style="width: 40px; height: 40px; color: #999999"
              ></b-icon>-->
              <img
                src="@/assets/ava.jpeg"
                style="
                  width: 50px;
                  height: 50px;
                  border-radius: 50%
                 "
                alt="profile_img"
              />
            </div>
            <div v-else>
              <img
                :src="data.item.image"
                style="
                  width: 50px;
                  object-fit: cover;
                  height: 50px;
                  border-radius: 50%;
                "
                alt="profile_img"
              />
            </div>
          </template>
          <template #cell(title)="data">
            <div>
              {{ data.item.title }}
            </div>
          </template>
          <template #cell(country_title)="data">
            <div>
              {{ data.item.country_title && data.item.country_title.en }}
            </div>
          </template>
          <template #cell(city_title)="data">
            <div>
              {{ data.item.city }}
            </div>
          </template>
          <template #cell(event_registration_type)="data">
            <div>
              {{
                data.item.event_registration_type &&
                data.item.event_registration_type.registration_type.title.ru
              }}
            </div>
          </template>
          <template #cell(gender)="data">
            <div>
              {{
                data.item.gender
                  ? data.item.gender === "M"
                    ? "Мужчина"
                    : "Женщина"
                  : "-"
              }}
            </div>
          </template>
          <template #cell(qr_code)="data">
            <div>
              <b-button
                v-if="data.item.qr"
                size="sm"
                variant="warning"
                @click="showMsgOk(data.item)">QR code
              </b-button>
              <b-button
                v-else
                size="sm"
                variant="danger"
              >
                No QR code
              </b-button>
            </div>
          </template>
          <template #cell(status)="data">
            <b-form-tag
              v-if="data.item.status"
              disabled
              class="status-btn"
              size="sm"
              :variant="statusColor[data.item.status]"
            >
              {{ data.item.status.toUpperCase() }}
            </b-form-tag>
          </template>
          <template #cell(birth_date)="data">
            <div>
              {{
                data.item.birth_date
                  ? $moment(data.item.birth_date).format("DD-MM-YYYY")
                  : "-"
              }}
            </div>
          </template>
          <template #cell(conference_participation)="data">
            <div>
              {{
                data.item.conference_participation !== null
                  ? data.item.conference_participation === true
                    ? "Да"
                    : "Нет"
                  : "-"
              }}
            </div>
          </template>
          <template #cell(created_at)="data">
            <div>
              {{ $moment(data.item.created_at).format("DD-MM-YYYY HH:mm:ss") }}
            </div>
          </template>
          <template #cell(session_title)="data">
            <p
              style="
                text-align: left;
                font-size: 12px;
                margin-bottom: 0;
                line-height: 20px;
              "
              v-for="(session, index) in data.item.sessions"
              :key="index"
            >
              {{ index + 1 + ". " + session.title }}
            </p>
          </template>
          <template #cell(hotel_name)="data">
            {{ data.item.booking_detail.hotel }}
          </template>
          <template #cell(check_in)="data">
            {{ data.item.booking_detail.check_in }}
          </template>
          <template #cell(check_out)="data">
            {{ data.item.booking_detail.check_out }}
          </template>
          <template #cell(payment_status)="data">
            <b-form-tag
              style="font-weight: bold"
              disabled
              :variant="paymentStatuses[data.item.payment_status]"
            >
              {{ data.item.payment_status.toUpperCase() }}
            </b-form-tag>
          </template>
          <template #cell(participation_type)="data">
            <b-dropdown
              v-if="data.item.participation_type"
              :text="data.item.participation_type"
              ref="dropdown"
              :variant="
                data.item.participation_type === 'free' ? 'info' : 'success'
              "
              size="sm"
            >
              <template v-for="(p_status, index) in participantStatuses">
                <b-dropdown-item
                  v-if="p_status.value !== data.item.participation_type"
                  :key="index"
                  @click="onClickParticipantStatus(p_status, data.item.id)"
                >
                  {{ p_status.text }}
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </template>
          <template #cell(flight_number)="data">
            {{ data.item.flight_detail?.flight_number }}
          </template>
          <template #cell(arrival_date)="data">
            {{
              data.item?.flight_detail?.flight_arrival_datetime &&
              moment(data.item?.flight_detail?.flight_arrival_datetime).format(
                "YYYY-MM-DD"
              )
            }}
            <img
              v-if="data.item?.flight_detail?.flight_arrival_datetime"
              style="height: 30px; margin-left: 4px; transform: rotate(45deg)"
              src="@/assets/airplane.png"
            />
          </template>
          <template #cell(arrival_time)="data">
            {{
              data.item?.flight_detail?.flight_arrival_datetime &&
              moment(data.item?.flight_detail?.flight_arrival_datetime).format(
                "HH:MM"
              )
            }}
            <img
              v-if="data.item?.flight_detail?.flight_arrival_datetime"
              style="height: 30px; margin-left: 4px; transform: rotate(45deg)"
              src="@/assets/airplane.png"
            />
          </template>
          <template #cell(departure_date)="data">
            <img
              v-if="data.item?.flight_detail?.flight_departure_datetime"
              style="height: 30px; margin-right: 4px"
              src="@/assets/airplane.png"
            />
            {{
              data.item?.flight_detail?.flight_departure_datetime &&
              moment(
                data.item?.flight_detail?.flight_departure_datetime
              ).format("YYYY-MM-DD")
            }}
          </template>
          <template #cell(departure_time)="data">
            <img
              v-if="data.item?.flight_detail?.flight_departure_datetime"
              style="height: 30px; margin-right: 4px"
              src="@/assets/airplane.png"
            />

            {{
              data.item?.flight_detail?.flight_departure_datetime &&
              moment(
                data.item?.flight_detail?.flight_departure_datetime
              ).format("HH:MM")
            }}
          </template>
          <template #cell(action)="data">
            <div class="boxx">
              <router-link :to="'/app/editUser/' + data.item.id">
                <div class="edit_box">
                  <b-icon
                    icon="pencil"
                    style="width: 15px; height: 15px; color: #fff"
                  ></b-icon>
                </div>
              </router-link>
              <b-button
                variant="success"
                class="btn-icon print_icon"
                @click.stop="badgePrint(data.item.id)"
              >
                <b-icon
                  icon="printer"
                  style="width: 15px; height: 15px; color: #fff"
                ></b-icon>
              </b-button>
              <b-button
                variant="danger"
                class="btn-icon"
                @click="delete_funk(data.item.id)"
                disabled
              >
                <b-icon
                  icon="trash"
                  style="width: 15px; height: 15px; color: #fff"
                ></b-icon>
              </b-button>
            </div>
          </template>
        </b-table>
        <!-- loading bar -->
        <b-overlay :show="busy" no-wrap></b-overlay>

        <!-- Pagination -->
        <div class="box_pagination" v-if="!busy">
          <div>
            Все: <strong> {{ totalRows }}</strong>
          </div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            @change="onChangePagination"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </div>
    <!-- Modal form start -->
    <div v-if="show_modal" class="fixedP" style="z-index: 1000">
      <div class="row row_box">
        <div class="col-8 col-sm-7 col-md-6 col-lg-5 col-xl-4">
          <div class="box">
            <div class="title">
              <h2>Удалить событие</h2>
            </div>
            <div class="paragrf">
              <p>
                Вы уверены,
                <br/>
                что хотите удалить <br/>
                это событие
              </p>
            </div>
            <button @click="delete_funk()" class="closee">Удалить</button>
            <button @click="close_funk" class="close_btn">Отмена</button>
          </div>
          <b-overlay :show="busy" no-wrap></b-overlay>
        </div>
      </div>
    </div>
    <notes ref="message"></notes>
  </div>
</template>

<script>
import print from "print-js";
import ToastificationContent from "@/components/ToastificationContent.vue";
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BIcon,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BRow,
  BTable,
} from "bootstrap-vue";
import {instance} from "@/utils/composables";
import Dropdown from "vue-simple-search-dropdown";
import data from "bootstrap/js/src/dom/data";
import moment from "moment";

export default {
  components: {
    ToastificationContent,
    BTable,
    BRow,
    BCol,
    BIcon,
    BPagination,
    BImg,
    BButton,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    Dropdown,
  },

  data() {
    return {
      show_modal: false,
      forDeleting: [],
      tableVariant: "light",
      color: "#354B9C",
      busy: true,
      items: [],
      selectedStatus: "",
      statuses: [
        {value: "", text: "All statuses"},
        {value: "pending", text: "PENDING"},
        {value: "accepted", text: "ACCEPTED"},
        {value: "canceled", text: "CANCELED"},
      ],
      participantStatuses: [
        {value: "paid", text: "PAID"},
        {value: "free", text: "FREE"},
        {value: "canceled", text: "CANCELED"},
      ],
      statusColor: {
        pending: "info",
        accepted: "primary",
        canceled: "danger",
        visitor: "success",
      },
      paymentStatuses: {
        paid: "success",
        unpaid: "warning",
        free: "primary",
      },
      fields: [
        {
          key: "selected",
          label: "",
          stickyColumn: true,
        },
        {
          key: "image",
          label: "Изображение",
        },
        {
          key: "full_name",
          label: "Имя",
          class: "text-start",
          sortable: true,
        },
        {
          key: "public_id",
          label: "Публичный ID",
          sortable: true,
        },
        {
          key: "gender",
          label: "Пол",
          sortable: true,
        },
        {
          key: "conference_participation",
          label: "Участие в конференции",
          sortable: true,
        },
        {
          key: "phone_number",
          label: "Телефон",
          sortable: false,
        },
        {
          key: "qr_code",
          label: "QR код",
          sortable: false,
        },
        {
          key: "payment_status",
          label: "Статус платежа",
          sortable: false,
        },
        {
          key: "status",
          label: "Статус",
          sortable: true,
        },
        {
          key: "participation_type",
          label: "Статус участия",
          sortable: true,
        },
        {
          key: "event_registration_type",
          label: "Тип регистрации",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Дата регистрации",
          sortable: true,
        },
        {
          key: "country_title",
          label: "Страна",
          sortable: false,
        },
        {
          key: "city_title",
          label: "Город",
          sortable: false,
        },
        {
          key: "organization",
          label: "Организация ",
          sortable: true,
        },
        {
          key: "position",
          label: "Позиция",
          sortable: true,
        },
        {
          key: "email",
          label: "Электронная почта",
          sortable: false,
        },
        {
          key: "birth_date",
          label: "Дата рождения",
          sortable: false,
        },
        {
          key: "passport_id",
          label: "Номер паспорта",
          class: "text-start",
          sortable: false,
        },
        {
          key: "work_address",
          label: "Рабочий адрес",
        },
        {
          key: "session_title",
          label: "Сессии",
        },
        {
          key: "hotel_name",
          label: "Номер гостиницы",
        },
        {
          key: "check_in",
          label: "Дата заезда",
        },
        {
          key: "check_out",
          label: "Дата выезда",
        },
        {
          key: "flight_number",
          label: "Номер авиарейса",
        },
        {
          key: "arrival_date",
          label: "Дата прилета",
        },
        {
          key: "arrival_time",
          label: "Время прилета",
        },
        {
          key: "departure_date",
          label: "Дата вылета",
        },
        {
          key: "departure_time",
          label: "Время вылета",
        },
        {
          key: "action",
          label: "Действие",
          stickyColumn: true,
        },
      ],
      sendModal: false,
      perPage: 10,
      totalItems: 1,
      active: true,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: "",
      filterOn: [],
      totalRows: 1,
      Item_id: null,
      deletingUser: null,
      file: null,
      // selectedEmail: "success_text",
      selectedEmail: "cancel_text",
      options: [
        // {value: "success_text", text: "Text of success"},
        // {value: "invitation_text", text: "Text of invitation"},
        {value: "cancel_text", text: "Text of cancel"},
        {value: "pending_text", text: "Text of pending"},
        {value: "payment_text", text: "Text of payment"},
      ],
      selectedRegType: "",
      regTypes: [
        {value: "", text: "All registration types"},
      ],
      countries: [],
      selectedCountry: "all",
      eventId: null,
      events: [],
      selectedEventId: null,
      timeout: null,
    };
  },

  computed: {
    moment() {
      return moment;
    },
    isAdmin() {
      return JSON.parse(localStorage.getItem("user"));
    },
    currentPage() {
      return this.$route.query.page ? this.$route.query.page : 1;
    },
  },

  async mounted() {
    await this.getCountries();
    await this.getData();
    await this.getEventRegistrationTypes();

    /*Vaqtinchalik!!!*/
    if (this.eventId === '12') {
      this.fields = [
        {
          key: "selected",
          label: "",
          stickyColumn: true,
        },
        // {
        //   key: "image",
        //   label: "Изображение",
        // },
        {
          key: "registration_type",
          // label: "Тип регистрации",
          label: "Тип участия",
          sortable: false,
        },
        {
          key: "full_name",
          label: "ФИО",
          class: "text-start",
          sortable: true,
        },
        {
          key: "public_id",
          label: "Публичный ID",
          sortable: true,
        },
        {
          key: "organization",
          label: "Организация",
          sortable: true,
        },
        {
          key: "position",
          label: "Должность",
          sortable: true,
        },
        {
          key: "email",
          // label: "Электронная почта",
          label: "Почта",
          sortable: false,
        },
        {
          key: "phone_number",
          label: "Телефон",
          sortable: false,
        },
        {
          key: "payment_status",
          label: "Статус платежа",
          sortable: false,
        },
        {
          key: "status",
          label: "Статус",
          sortable: true,
        },
        {
          key: "participation_type",
          label: "Статус участия",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Дата регистрации",
          sortable: true,
        },
        /*{
          key: "gender",
          label: "Пол",
          sortable: true,
        },
        {
          key: "passport_id",
          label: "Номер паспорта",
          class: "text-start",
          sortable: false,
        },
        {
          key: "birth_date",
          label: "Дата рождения",
          sortable: false,
        },
        {
          key: "country_title",
          label: "Страна",
          sortable: true,
        },
        {
          key: "work_address",
          label: "Рабочий адрес",
        },
        {
          key: "session_title",
          label: "Сессии",
        },
        {
          key: "hotel_name",
          label: "Номер гостиницы",
        },
        {
          key: "check_in",
          label: "Дата заезда",
        },
        {
          key: "check_out",
          label: "Дата выезда",
        },
        {
          key: "flight_number",
          label: "Номер авиарейса",
        },
        {
          key: "arrival_date",
          label: "Дата прилета",
        },
        {
          key: "arrival_time",
          label: "Время прилета",
        },
        {
          key: "departure_date",
          label: "Дата вылета",
        },
        {
          key: "departure_time",
          label: "Время вылета",
        },*/
        {
          key: "action",
          label: "Действие",
          stickyColumn: true,
        },
      ];
    }
  },

  methods: {
    onInputSearch() {
      clearTimeout(this.timeout);
      if (this.$route.query.page !== "1") {
        this.$router.replace({query: {page: "1"}});
      }
      this.timeout = setTimeout(() => this.getData(), 1000);
    },
    onChangePagination(val) {
      this.$router.replace({query: {page: val}}).then(() => {
        this.getData();
      });
    },
    onFilterCountry(country) {
      this.$router.replace({query: {page: "1"}}).catch((err) => {
        console.log(err);
      });
      this.selectedCountry = parseInt(country?.id) ? country?.id : "";
      if (this.selectedCountry) {
        this.getData();
      }
    },
    getCountries() {
      instance.get("/event/countries/", {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }).then(({data}) => {
        this.countries = data.map((country) => {
          return {id: country.id, name: country.title.en};
        });
      }).catch(() => {
        this.$toast.warning("Страны не загружаются");
      }).finally(() => {
        this.countries.unshift({id: "all", name: "All countries"});
      });
    },
    async getEventRegistrationTypes() {
      try {
        await this.$store.dispatch("getEventRegistrationTypes", this.eventId)
        const types = this.$store.state.eventRegistrationTypes
        // console.log("types: ", types)
        if (types && types.length > 0) {
          types.forEach((regType) => {
            this.regTypes.push({value: regType.id, text: regType.registration_type.title.ru});
          });
          // console.log("regTypes: ", this.regTypes)
        }
      } catch (err) {
        console.error(err);
      }
    },
    onChangeFilter() {
      this.$router.replace({query: {page: "1"}}).catch(() => {
      });
      this.getData();
    },
    onClickParticipantStatus(status, id) {
      const data = new FormData();
      this.busy = true;
      data.append("participation_type", status.value);
      instance.post(`/participant/${id}/participation-update/`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }).then(() => {
        this.getData();
      }).catch(() => {
        this.$toast.error("Не удалось изменить статус");
      }).finally(() => {
        this.busy = false;
      });
    },
    onChangeCheckBox(selected, id) {
      this.items = this.items.map((item) => {
        if (item.id === id) {
          item.selected = selected;
        }
        return item;
      });
      this.forDeleting = this.items.map((item) => {
        if (item.selected) {
          return item.id;
        }
      });
      this.forDeleting = this.forDeleting.filter(
        (element) => element !== undefined
      );
    },
    selectAllRows() {
      this.items = this.items.map((item) => {
        item.selected = true;
        return item;
      });
      this.forDeleting = this.items.map((item) => {
        if (item.selected) {
          return item.id;
        }
      });
    },
    clearSelected() {
      this.items = this.items.map((item) => {
        item.selected = false;
        return item;
      });
      this.forDeleting = [];
    },
    async sendEmail() {
      try {
        const event_id = this.eventId;
        const emails = this.forDeleting;
        const text_type = this.selectedEmail;
        const data = {event_id, emails, text_type};
        const response = await instance.post(
          "/event/send-mail/",
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response);
        this.$toast.success("Mails are sending");
      } catch (e) {
        console.log(e.response.data);
      }
    },
    // changeCheckbox(e) {
    //   e.rowSelected = !e.rowSelected;
    // },
    async importXls() {
      try {
        let eventId = this.eventId;
        await this.$store.dispatch("export", {
          event: eventId,
          status: this.selectedStatus,
          country: this.selectedCountry,
          registration_type: this.selectedRegType,
        });
      } catch (err) {
        console.log(err);
      }
    },
    // rowSelected(e) {
    //   this.forDeleting = e.map((data) => data.id);
    //   console.log(this.forDeleting);
    // },
    async deleteMultipleData() {
      this.$bvModal.msgBoxConfirm(
        "Пожалуйста, подтвердите, что вы действительно хотите удалить",
        {
          title: "Предупреждение",
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Удалить",
          okClass: "btn-icon",
          cancelTitle: "Отмена",
          cancelVariant: "outline-dark",
          cancelClass: "btn-icon",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        }
      ).then(async (value) => {
        if (value) {
          try {
            this.busy = true;
            let formData = new FormData();
            formData.append("ids", JSON.stringify(this.forDeleting));
            console.log(this.forDeleting);
            await this.$store.dispatch(
              "deleteMultiple",
              JSON.stringify({ids: this.forDeleting})
            );
            await this.getData();
          } catch (err) {
            console.log(err);
          } finally {
            this.busy = false;
          }
        }
      });
    },
    async delete_funk(id) {
      this.$bvModal.msgBoxConfirm(
        "Пожалуйста, подтвердите, что вы действительно хотите удалить",
        {
          title: "Предупреждение",
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Удалить",
          okClass: "btn-icon",
          cancelTitle: "Отмена",
          cancelVariant: "outline-dark",
          cancelClass: "btn-icon",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        }
      ).then(async (value) => {
        if (value) {
          try {
            this.busy = true;
            await this.$store.dispatch("deleteUser", id);
            await this.getData();
          } catch (error) {
            console.log(error);
            this.busy = false;
          }
        }
      });
    },
    close_funk() {
      this.show_modal = !this.show_modal;
    },
    async upload(e) {
      try {
        this.busy = true;
        this.file = e.target.files[0];
        const data = new FormData();
        data.append("event", this.eventId);
        data.append("file", this.file);
        await this.$store.dispatch("uploadFile", data);
        await this.getData();
      } catch (er) {
        console.log(er);
      } finally {
        this.busy = false;
      }
    },
    // Delete Items
    deleteItem(id) {
      this.show_modal = !this.show_modal;
      this.deletingUser = id;
    },
    // Print Item
    async badgePrint(id) {
      try {
        this.busy = true;
        //  a.print()
        const response = await instance.get(`/participant/${id}/badge/`, {
          headers: {
            Authorization: `Bearer ${localStorage.accessToken}`,
          },
        });
        // print(response.data.file);

        const base64str = response.data.base64;
        const binary = atob(base64str.replace(/\s/g, ""));
        const len = binary.length;
        const buffer = new ArrayBuffer(len);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
        }
        const blob = new Blob([view], {type: "application/pdf"});
        const blobURL = URL.createObjectURL(blob);
        print(blobURL);
      } catch (err) {
        console.log(err);
        alert("Для этого события не сгенерирован шаблон");
      } finally {
        this.busy = false;
      }
    },
    print(id) {
      console.log("Printing item id", id);
    },
    pageClicked(e) {
      console.log(e);
    },

    getData() {
      this.busy = this.busy ? this.busy : !this.busy;
      this.items = [];
      let page = this.$route.query.page ? this.$route.query.page : 1;
      const data = {
        event: this.eventId,
        event_registration_type: this.selectedRegType,
        status: this.selectedStatus,
        country: this.selectedCountry,
        page: page,
        page_size: this.perPage,
        search: this.filter,
      };
      instance.get(
        `/participant/?event=${data.event}&event_registration_type=${data.event_registration_type}&status=${data.status}&country=${data.country}&page=${data.page}&page_size=${data.page_size}&search=${data.search}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.accessToken}`,
          },
        }
      ).then(({data}) => {
        this.items = data.results.map((item) => {
          return item.badge_printed
            ? {...item, _rowVariant: "success"}
            : item;
        });
        this.totalRows = data.count;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.busy = false;
      });
    },

    showMsgOk(data) {
      // console.log(data)
      if (data.qr && data.qr.image) {
        const h = this.$createElement
        // Using HTML string
        const titleVNode = h('div', {domProps: {innerHTML: 'Title from <i>HTML<i> string'}})
        // More complex structure
        const messageVNode = h('div', {class: ['foobar']}, [
          h('p', {class: ['text-center']}, [
            'Public ID: ',
            h('strong', data.public_id),
          ]),
          h('b-img', {
            props: {
              src: data.qr.image,
              thumbnail: true,
              center: true,
              fluid: true,
              // rounded: 'circle'
            }
          })
        ])
        // We must pass the generated VNodes as arrays
        this.$bvModal.msgBoxOk([messageVNode], {
          title: [titleVNode],
          buttonSize: 'sm',
          centered: true,
          // size: 'sm'
        })
      } else {
        this.$bvModal.msgBoxOk("QR code not found", {
          title: "QR code",
          buttonSize: "sm",
          centered: true,
          // size: "sm",
        });
      }
    }
  },

  created() {
    this.eventId = parseInt(localStorage.getItem("event"))
      ? localStorage.getItem("event")
      : null;
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Main {
  height: 100vh;
  width: 100%;
  background: #f8f7ff;
}

.table > :not(:first-child) {
  border-top: none !important;
}

.table td {
  vertical-align: middle;
}

.status-btn {
  border: none !important;
  font-weight: bold;
}

.dropdown.b-dropdown.btn-group {
  align-items: start;
}

.dropdown-menu {
  position: absolute !important;
  font-size: 14px;
  z-index: 200;
}

.dropdown-toggle {
  border: none !important;
  font-weight: bold;
  font-size: 13px;
  color: #ffffff !important;
}

.b-table-sticky-column {
  right: 0;
}

.table_in {
  height: calc(100vh - 270px);
  margin-bottom: 0 !important;
}

thead th {
  vertical-align: middle !important;
}
</style>
