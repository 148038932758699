import { render, staticRenderFns } from "./RegisterForm.vue?vue&type=template&id=55b1d332&scoped=true&"
import script from "./RegisterForm.vue?vue&type=script&lang=js&"
export * from "./RegisterForm.vue?vue&type=script&lang=js&"
import style0 from "./RegisterForm.vue?vue&type=style&index=0&id=55b1d332&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55b1d332",
  null
  
)

export default component.exports